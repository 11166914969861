/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

.ant-select-dropdown {
  z-index: 1302 !important;
}
.ant-picker-dropdown {
  z-index: 1303 !important;
}

.ant-table-placeholder {
  z-index: 100 !important;
}

.ant-tabs-dropdown {
  z-index: 1400 !important;
}

.ant-tooltip {
  z-index: 1401 !important;
}

.ant-modal-wrap {
  z-index: 1301 !important;
}

.ant-popover {
  z-index: 1302 !important;
}

.ck-balloon-panel {
  z-index: 1305 !important;
}
